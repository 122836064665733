<template>
  <div class="home">
    <!-- <p class="hello">Hallo Familie Mustermann . . .</p>
    <img alt="Bambus" class="bambus" src="../assets/bambus.png" />
    <p>
      Du hast dich bereits zurückgemeldet! <br />
      Hier stehen deine Daten
    </p>

    <v-btn color="primary"> Rückmeldung ändern! </v-btn>
    <v-text-field
      class="input-content"
      outlined
      dense
      rounded
      label="test"
    ></v-text-field> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
